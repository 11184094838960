import './vendor';
import lazyLoading from './modules/lazyLoading';
import Vivus from '../../node_modules/vivus/dist/vivus.js';
require('/src/js/vendor/magnific/jquery.magnific-popup.min.js')
require('../../node_modules/@cmyee/pushy/js/pushy.js')

lazyLoading.init();


$('.slider').slick({
	dots: true,
	speed: 1000,
	infinite: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	prevArrow: $("#slider-prev"),
	nextArrow: $("#slider-next"),
	asNavFor: '.slider-thumb',
});

$('.slider-thumb').slick({
	speed: 1000,
	slidesToShow: 1,
	slidesToScroll: 1,
	asNavFor: '.slider',
	focusOnSelect: true,
	arrows: false,
});


/*Magnific модальное окно */
$('.popup-with-form').magnificPopup({
	type: 'inline',
	preloader: false,
	focus: '#name',
	mainClass: 'mfp-fade',
	callbacks: {
		beforeOpen: function() {
			if($(window).width() < 700) {
				this.st.focus = false;
			} else {
				this.st.focus = '#name';
			}
		}
	}
});


$(".scroll").on("click","a", function (event) {
    // исключаем стандартную реакцию браузера
    event.preventDefault();

    // получем идентификатор блока из атрибута href
    var id  = $(this).attr('data-scroll-to'),

    // находим высоту, на которой расположен блок
        top = $(id).offset().top;

    // анимируем переход к блоку, время: 800 мс
    $('body,html').animate({scrollTop: top}, 800);
});


/*Magnific галерея*/
$('.popup-gallery').magnificPopup({
	delegate: 'a',
	type: 'image',
	tLoading: 'Загрузка изображения #%curr%...',
	mainClass: 'mfp-fade mfp-img-mobile',
	gallery: {
		enabled: true,
		navigateByImgClick: true,
		preload: [0,1] // Will preload 0 - before current, and 1 after the current image
	},
	image: {
		tError: '<a href="%url%">Изображение #%curr%</a> не загружено.',
		titleSrc: function(item) {
			return '';
		}
	}
});
